<template>
  <el-row class="flex-height px-70">
    <el-col :span="24">
      <img src="@/assets/img/YRlogo.png" height="50" alt=""
           class="mt-15 cp"
           @click="goPage('system')">
    </el-col>
    <div class="loginBox">
      <div class="fs-20 cp tc-white mb-40">
        <span :class="{'tc-weak': tabValue !== 'person'}"
            @click="tabValue = 'person'">登 录</span>
<!--        <span class="mx-20 tc-weak">|</span>-->
<!--        <span :class="{'tc-weak': tabValue !== 'enterprise'}"-->
<!--            @click="tabValue = 'enterprise'">企业登录</span>-->
      </div>
      <!-- 个人用户 -->
      <div v-show="tabValue === 'person'" class="">
        <div id="wx_login_container" style="height: 200px">
          <el-image
              style="width: 200px; height: 200px"
              :src="qrcodeSrc">
            <div slot="error" class="image-slot">
              <i class="el-icon-refresh-right cp" @click="getQRCode">点击刷新</i>
            </div>
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>
        <div class="fs-16 my-15 tc-white">微信扫码关注公众号登录</div>
      </div>
      <!-- 企业用户 -->
      <div v-show="tabValue === 'enterprise'" class="pt-20 px-5">
        <formList
            ref="ruleForm"
            :formLabel="formLabel"
            :form="form"
            formLabelWidth="20px"
            :rules="formRules"
        ></formList>
        <el-button type="primary" class="mt-60" style="width: 87%">登 录</el-button>
      </div>
    </div>
  </el-row>
</template>

<script>
import formList from "@/components/formList";
import publicFun from "@/mixins/public";

export default {
  name: "login",
  mixins: [publicFun],
  components: {formList},
  data() {
    return {
      tabValue: 'person',
      qrcodeSrc: '',
      formLabel: [
        {
          colSpan: 24,
          label: '',
          type: 'text',
          key: 'company',
          placeholder: '请输入企业名称'
        },
        {
          colSpan: 24,
          label: '',
          type: 'text',
          key: 'company_num',
          placeholder: '请输入企业税号'
        }
      ],
      form: {
        company: '',
        company_num: ''
      },
      formRules: {
        company: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        company_num: [
          { required: true, message: '请输入企业税号', trigger: 'blur' }
        ]
      },
      redirectURL: '',
      timeStamp: null,
      codeTimer: null,
      codeTime: 0
    }
  },
  mounted() {
    // this.redirectURL = sessionStorage.fromRoute ? sessionStorage.fromRoute : location.origin + '/#/home';
    this.redirectURL = sessionStorage.fromRoute ? sessionStorage.fromRoute : location.origin + '/#/home';
    this.getQRCode();
  },
  methods: {
    /* 微信扫码关注公众号登录 */
    getQRCode() {
      this.codeTime = 20;
      this.$axios
          .post('/yrservice/wxLoginCode/getQrCode/')
          .then((res) => {
            const {
              msg,
              data,
              code
            } = res;
            if (code === 200) {
              this.qrcodeSrc = data.codeUrl;
              this.timeStamp = data.timeStamp;
              let _this = this;
              setTimeout(() => {
                _this.setCount();
              }, 2000);
            }
          })
          .catch(err => {
            console.log(err)
          });
    },
    /* 设置定时器 */
    setCount() {
      this.codeTimer = setInterval(() => {
        if (this.codeTime <= 0) {
          this.clearCount();
          this.qrcodeSrc = null;
          this.timeStamp = null;
        } else {
          let params = {
            timeStamp: this.timeStamp
          };
          this.$axios
              .get('/yrservice/wxLogin/pollingLogin/', {params: params})
              .then(res => {
                if (res.code === 200) {
                  let userinfo = res.data;
                  if (userinfo.unionid) {
                    sessionStorage.userinfo = JSON.stringify(userinfo);
                    this.clearCount();
                    location.href = this.redirectURL;
                  }
                }
              }).catch(err => {
                console.log(err);
                this.clearCount();
              });
          this.codeTime--;
        }
      },2000);
    },
    /* 清除定时器 */
    clearCount() {
      clearInterval(this.codeTimer);
      this.codeTimer = null;
      this.codeTime = 0;
    }
  }
}
</script>

<style scoped lang="scss">
.flex-height {
  height: 100vh;
  background-image: url("http://47.94.45.53:10009/image/htmlImg/bg.jpg");
  background-size: cover;
  background-position: center;
}
.loginBox {
  position: fixed;
  left: 60%;
  top: 50%;
  width: 350px;
  height: 420px;
  margin-top: -210px;
  padding: 50px 15px;
  background: rgba(0,0,0,0.4);
  box-shadow: 0 0 5px #777;
  border: 1px solid rgba(0,0,0,0.4);
  backdrop-filter: blur(2px);
  text-align: center;
}
.el-form {
  ::v-deep {
    .el-form-item__label {
      color: #fff;
    }
  }
}
#wx_login_container {
  ::v-deep {
    .title {
      display: none;
    }
    .impowerBox .qrcode {
      width: 240px;
    }
  }
}
.el-image {
  ::v-deep {
    .image-slot {
      width: 200px;
      height: 200px;
      line-height: 200px;
      background: #F5F7FA;
      font-size: 16px;
    }
  }
}
</style>